<template>
  <v-container
    class="products-list-page"
    fluid>
    <image-preview v-model="imagePreview" />

    <v-row
      justify="space-between"
      align="center"
      class="head-search ma-0 rounded">
      <v-col cols="3">
        <v-btn
          :disabled="!products.length"
          :loading="syncLoading"
          color="secondary"
          @click="syncAllStocks()">
          <v-icon>mdi-cached</v-icon>
          Sync All ({{ totalMappedProduct }})
        </v-btn>
      </v-col>
      <v-col
        class="myshop-search"
        cols="2">
        <v-select
          v-model="query.isMapped"
          class="pt-1"
          hide-details
          dense
          solo
          :items="mappingOptions"
          item-text="text"
          item-value="value">
        </v-select>
      </v-col>
      <v-col cols="7">
        <search-by-tags-and-brand
          v-model="query"
          append-icon="mdi-magnify"
          tags-width="300px"
          @on-search="searchProduct()" />
      </v-col>
    </v-row>

    <v-expansion-panels
      v-model="panels"
      class="mt-2">
      <product-box
        v-for="(p, i) in products"
        :key="i"
        v-bind="p"
        :panel-active="isPanelActive(i)"
        :can-sync="p.isMapped"
        @preview-image="previewImg($event)" />
    </v-expansion-panels>

    <v-progress-circular
      v-if="loading"
      class="overlay-progress"
      :size="70"
      :width="7"
      color="black"
      indeterminate />

    <v-row
      v-if="!products.length && !loading"
      justify="center"
      align="center">
      <v-col
        cols="auto"
        class="my-15">
        <p>There aren't any products. :( </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import ProductProvider from '@/resources/ProductProvider'
import LineMyShopProvider from '@/resources/LineMyShopProvider'
import ConvertQueryStringToArray from '@/assets/js/ConvertQueryStringToArray'
import SearchByTagsAndBrand from '@/components/SearchByTagsAndBrand.vue'
import ImagePreview from '@/views/management/components/ImagePreview.vue'
import ProductBox from '../components/ProductBox.vue'

const LineMyShopService = new LineMyShopProvider()
const ProductService = new ProductProvider()

export default {
  components: { ImagePreview, ProductBox, SearchByTagsAndBrand },
  data () {
    return {
      panels: [],
      products: [],
      loading: false,
      syncLoading: false,
      query: {
        page: 0,
        limit: 10,
        sortBy: 'createdAt',
        sortOrder: 'desc',
        search: '',
        tags: [],
        tagOperation: 'OR',
        brand: 'all',
        isMapped: 'all'
      },
      totalPage: 1,
      totalProduct: 0,
      totalMappedProduct: 0,
      imagePreview: {
        active: false,
        imgSrc: null
      },
      mappingOptions: [
        {
          text: 'All',
          value: 'all'
        },
        {
          text: 'Mapped',
          value: 'true'
        },
        {
          text: 'Not Mapped',
          value: 'false'
        }
      ]
    }
  },
  mounted () {
    if (this.$route.query) {
      const result = ConvertQueryStringToArray(this.$route.query)

      this.query = {
        page: 0,
        limit: 10,
        sortBy: result.sortBy || 'createdAt',
        sortOrder: result.sortOrder || 'desc',
        search: result.search || '',
        tags: result.tags || [],
        tagOperation: result.tagOperation || 'OR',
        brand: result.brand || 'all',
        isMapped: result.isMapped || 'all'
      }
    }

    window.addEventListener('scroll', this.handleScroll)
    this.fetchProducts(true)
    this.getCountMappedProducts()
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    ...mapActions({
      setErrorPage: 'Components/setErrorPage',
      setSnackbar: 'Components/setSnackbar'
    }),
    handleScroll (event) {
      const e = event.target.scrollingElement
      const scrollY = e.scrollHeight - e.clientHeight - 1000
      if (e.scrollTop >= scrollY && !this.loading) {
        this.fetchProducts()
      }
    },
    async syncAllStocks () {
      try {
        this.syncLoading = true
        this.loading = true

        await LineMyShopService.syncAllStocksLineMyShopProducts()
        this.fetchProducts(true)
        this.getCountMappedProducts()

        this.setSnackbar({
          value: true,
          message: 'Sync all stocks success',
          type: 'success'
        })
      } catch (error) {
        console.error('syncAllStocks', error)
        this.setSnackbar({
          value: true,
          message: `[SYNC-ALL-STOCKS-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.syncLoading = false
        this.loading = false
      }
    },
    async getCountMappedProducts () {
      try {
        this.syncLoading = true

        const { data } = await LineMyShopService.countLineMyShopMappedProducts()
        this.totalMappedProduct = data[0]?.count || 0
      } catch (error) {
        console.error('getCountMappedProducts', error)
        this.setSnackbar({
          value: true,
          message: `[GET-MAPPED-PRODUCTS-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.syncLoading = false
      }
    },
    async fetchProducts (isClear = false) {
      if (isClear) {
        this.query.page = 0
        this.products = []
      }

      try {
        this.loading = true

        if (this.query.page < this.totalPage) {
          this.query.page++

          const { data } = await ProductService.getMyShopProducts(this.query)
          this.totalPage = data.totalPages
          this.totalProduct = data.totalDocs
          this.products.push(...data.results)
        }
      } catch (error) {
        console.error('fetchProducts', error)
        this.setErrorPage(error.code)
      } finally {
        this.loading = false
      }
    },
    searchProduct () {
      this.$router.push({
        name: 'LineMyShop',
        query: {
          search: this.query.search,
          tags: this.query.tags || [],
          tagOperation: this.query.tagOperation,
          brand: this.query.brand,
          isMapped: this.query.isMapped
        }
      })

      this.fetchProducts(true)
      this.getCountMappedProducts()
    },
    previewImg (imgSrc) {
      this.imagePreview = {
        active: true,
        imgSrc
      }
    },
    createProductMapping () {
      this.$router.push({ name: 'LineMyShopCreateForm' })
    },
    isPanelActive (index) {
      return this.panels === index
    }
  }
}
</script>

<style scoped>
.overlay-progress {
  margin-top: 50px;
  left: 50%;
  transform: translateX(-50%);
}
</style>

<style>
.myshop-search .v-input__control {
  max-width: unset !important;
  min-height: 28px !important;
  max-height: 28px !important;
}
.myshop-search .v-input__slot {
  max-width: unset !important;
  min-height: 28px !important;
  max-height: 28px !important;
}</style>
