import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class LineMyShopProvider extends HttpRequest {
  getProducts (query) {
    this.setHeader(getAuthToken())
    return this.get('/line-myshop', query)
  }

  getLineMyShopProducts (query) {
    this.setHeader(getAuthToken())
    return this.get('/line-myshop/myshop-products', query)
  }

  getLineMyShopProductStock (id, brand) {
    this.setHeader(getAuthToken())
    return this.get(`/line-myshop/${id}/myshop-stock?brand=${brand}`)
  }

  createProductsMapping (payload) {
    this.setHeader(getAuthToken())
    return this.post('/line-myshop', payload)
  }

  updateProductsMapping (payload) {
    this.setHeader(getAuthToken())
    return this.patch(`/line-myshop/${payload.id}`, payload)
  }

  countLineMyShopMappedProducts () {
    this.setHeader(getAuthToken())
    return this.get('/line-myshop/count-products')
  }

  syncAllStocksLineMyShopProducts () {
    this.setHeader(getAuthToken())
    return this.get('/line-myshop/sync')
  }

  syncStocksLineMyShopProducts (id, brand) {
    this.setHeader(getAuthToken())
    return this.get(`/line-myshop/${id}/sync?brand=${brand}`)
  }

  deleteProductsMapping (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/line-myshop/${id}`)
  }
}

export default LineMyShopProvider
