<template>
  <v-dialog
    v-model="dialog.active"
    width="600px">
    <v-card class="preview-image-card">
      <div class="preview-image-box">
        <img
          :src="dialog.imgSrc"
          class="preview-image"
          alt="preview-image">
      </div>
      <v-btn
        class="preview-image-close"
        icon
        small
        @click="closeDialog()">
        <v-icon color="black">
          mdi-close
        </v-icon>
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (newVal) {
        this.$emit('input', newVal)
      }
    }
  },
  methods: {
    closeDialog () {
      this.dialog = {
        active: false,
        imgSrc: null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.preview-image-box {
  overflow-y: hidden;
}
.preview-image-card {
  position: relative;
  img.preview-image {
    width: 100%;
  }
  .preview-image-close {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
</style>
