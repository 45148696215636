<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <v-row class="product-row">
        <v-col
          cols="auto"
          class="product-col-left">
          <gw-product-image
            :src="photoUrls[0]"
            class="cursor-pointer rounded"
            @click="previewImg(photoUrls[0])" />
        </v-col>
        <v-col cols="auto">
          <p>
            <strong class="mr-1">
              Product Name :
            </strong>
            {{ name }}
          </p>
          <p>
            <strong class="mr-1">
              Product Model :
            </strong>
            {{ model }}
          </p>
          <p>
            <strong class="mr-1">
              Product Brand :
            </strong>
            {{ brand }}
          </p>
        </v-col>
        <v-spacer />
        <v-col
          v-if="canSync"
          cols="auto">
          <v-btn
            :loading="loading"
            color="success"
            small
            @click="syncStock()">
            <v-icon
              small
              class="mr-1">
              mdi-cached
            </v-icon>
            Sync
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn
            :loading="loading"
            color="secondary"
            small
            @click="editProductMapping()">
            <v-icon
              small
              class="mr-1">
              mdi-pencil
            </v-icon>
            Edit
          </v-btn>
        </v-col>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-progress-circular
        v-if="loading"
        class="overlay-progress"
        :size="50"
        :width="7"
        color="black"
        indeterminate />
      <v-simple-table
        v-else-if="inventories.length"
        class="skus-table pa-3 pt-0">
        <thead>
          <tr>
            <th
              v-for="(head, i) in headers"
              :key="`header-${i}`"
              class="text-center"
              :class="head.headClass">
              {{ head.text }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(sku, i) in inventories"
            :key="`skus-${i}`">
            <td
              v-for="(head, h) in headers"
              :key="h"
              align="center"
              :class="head.class">
              {{ sku[head.value] }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <div
        v-else
        class="text-center">
        This product isn't mapping.
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapActions } from 'vuex'
import ProductProvider from '@/resources/ProductProvider'
import LineMyShopProvider from '@/resources/LineMyShopProvider'
import SizeValues from '@/assets/js/SizeValues'

const LineMyShopService = new LineMyShopProvider()
const ProductService = new ProductProvider()

export default {
  props: {
    panelActive: {
      type: Boolean,
      default: false
    },
    canSync: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    model: {
      type: String,
      default: null
    },
    brand: {
      type: String,
      default: null
    },
    photoUrls: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      loading: false,
      inventories: [],
      headers: [
        {
          text: 'Product Code',
          value: 'code'
        },
        {
          text: 'Color',
          value: 'color',
          class: 'text-capitalize'
        },
        {
          text: 'Size',
          value: 'size',
          class: 'text-capitalize'
        },
        {
          text: 'MyShop Code',
          value: 'sku',
          headClass: 'text-green'
        },
        {
          text: 'Price',
          value: 'price',
          headClass: 'text-green'
        },
        {
          text: 'Reserved',
          value: 'reservedNumber',
          headClass: 'text-green'
        },
        {
          text: 'Ready to ship',
          value: 'readyToShipNumber',
          headClass: 'text-green'
        },
        {
          text: 'Available',
          value: 'availableNumber',
          headClass: 'text-green'
        },
        {
          text: 'In Stock',
          value: 'onHandNumber',
          headClass: 'text-green'
        },
        {
          text: 'Stock',
          value: 'stock'
        },
        {
          text: 'Sync Date',
          value: 'syncDate'
        }
      ]
    }
  },
  watch: {
    panelActive (val) {
      if (val) {
        this.getMyShopStock()
      }
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar'
    }),
    async getMyShopStock () {
      try {
        this.loading = true

        const { data } = await ProductService.getMyShopStocksByProductId(this.id)

        if (!data) {
          return
        }

        const sorted = data.skus.sort((a, b) => SizeValues(a.size.name) - SizeValues(b.size.name))
        const warehouseId = this.brand === null ? 964 : 1072
        this.inventories = sorted.map((s) => {
          const item = data.mappedProducts.find((m) => m.sku.id === s.id)
          const inventory = data.inventories.find((d) => d?.inventoryId === item?.inventoryId)
          // const stocks = s.stock.filter((stock) => stock.warehouse.id !== 0)
          const stocks = s.stock.filter((stock) => stock.warehouse.id === warehouseId)
          const stockRemain = stocks.reduce((sum, stock) => sum + (stock.onHandQty - stock.onReservedQty), 0)

          return {
            id: inventory?.id || null,
            sku: inventory?.sku || '-',
            reservedNumber: `${inventory?.reservedNumber === undefined ? '-' : inventory?.reservedNumber}`,
            readyToShipNumber: `${inventory?.readyToShipNumber === undefined ? '-' : inventory?.readyToShipNumber}`,
            availableNumber: `${inventory?.availableNumber === undefined ? '-' : inventory?.availableNumber}`,
            onHandNumber: `${inventory?.onHandNumber === undefined ? '-' : inventory?.onHandNumber}`,
            price: inventory?.price || '-',
            stock: stockRemain,
            skuId: s.id,
            code: s.code,
            color: s?.color?.name || '-',
            size: s?.size?.name.toUpperCase() || '-',
            syncDate: item?.syncDate ? this.$dayjs(item?.syncDate).format('DD/MM/YYYY HH:mm') : '-'
          }
        })
      } catch (error) {
        console.error('getMyShopStock', error)
        this.setSnackbar({
          value: true,
          message: `[GET-MYSHOP-STOCK-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    previewImg (img) {
      this.$emit('preview-image', img)
    },
    editProductMapping () {
      this.$router.push({ name: 'LineMyShopEditForm', params: { id: this.id } })
    },
    async syncStock () {
      try {
        this.loading = true

        await LineMyShopService.syncStocksLineMyShopProducts(this.id, this.brand)
        this.setSnackbar({
          value: true,
          message: 'Sync stock success',
          type: 'success'
        })
      } catch (error) {
        console.error('syncStock', error)
        this.setSnackbar({
          value: true,
          message: `[SYNC-STOCK-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.skus-table th {
  text-transform: capitalize;
}
.overlay-progress {
  margin-top: 50px;
  left: 50%;
  transform: translateX(-50%);
}
.text-capitalize {
  text-transform: capitalize;
}
.text-green {
  color: #18a352 !important;
}
</style>
